<template>
    <div>
        <Header :id="id" :exhibitionId="exhibitionId"></Header>
        <div class="spbanner">
            <img :src="conmpany.indexThumb" alt="">
        </div>
        <!-- 公司 -->
        <div class="spinfo">
            <div class="spinfo1">
                <div class="splist1">
                    <div class="spimg1">
                        <img :src="conmpany.companyThumb" alt="">
                    </div>
                    <div class="sptxt1">
                        <div class="sptit">
                            <div class="tag"></div>
                            <div class="sptit1">{{ $t('Company.Profile') }}</div>
                        </div>
                        <div class="sptit2">
                           {{conmpany.companyDesc}}
                        </div>
                    </div>
                </div>
                <!-- 视频 -->
                 <div class="live" v-if="videos.length>0">
                     <div class="tag"></div>
                     <div class="video"> {{ $t('live.video') }}</div>
                 </div>
                 <div class="livevideo" v-if="videos.length>0">
                     <div class="video1" v-for="(item,index) in videos" :key="index">
                         <div class="live1">
                            <video :src="item.url" controls="controls" class="videoimg"></video>
                         </div>
                          <div class="videotxt">{{item.title}}</div>
                     </div>
                 </div>
                 <!-- 产品 -->
                 <div class="pro" v-if="products">
                     <div class="tag"></div>
                     <div class="duct">{{ $t('product.recommendation') }}</div>
                 </div>
                 <div class="pro1">
                     <div class="duct1" v-for="(item,index) in products" :key="index">
                         <div class="proimg">
                           <router-link :to="{ name: 'Productdetail', params: { id: id, productId: item.productId } }">
                             <img :src="item.thumb" alt="">
                           </router-link>
                         </div>
                         <div class="protxt">
                            {{item.productName}}
                         </div>
                         <div class="protxt2">
                             {{ $t('FOBPrice') }}: <span>{{ $t('Negotiable') }}</span>
                         </div>
                         <div class="protxt3">{{ $t('Min.Order') }}: {{item.minOrder}}</div>
                         <div class="rtxt6">
                            <div class="rtxt5">{{ $t('contact.now') }}</div>
                            <div class="rheart"> <a-icon type="heart" /></div>
                        </div>
                     </div>
                 </div>
                <Contact></Contact>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "../components/Footer";
import Contact from "./components/Contact";

export default {
  name: "Supplier",
  components: {
    Footer,
    Header,
    Contact
  },
  data () {
    return {
        id: this.$route.params.id,
        exhibitionId: this.$route.params.exhibitionId,
        exhi: [],
        products: [],
        videos: [],
        conmpany: [],
        token: localStorage.token
    }   
  },
  mounted () {
      if (this.token == '') {
        this.$layer.msg('请先登录');
        this.$router.go(-1)
      }
      this.getList()
      this.getConmpany()
  },
   methods: {
    // 获取首页详情
    getList () {
      this.$common.fetchList('/company/index?companyId='+this.id+"&exhibitionId="+this.exhibitionId, {}).then(data => {
        if (data.code === 200) {
         const result = data.data
          for(const [k, v] of Object.entries(result)) {
            this[k] = v
          }
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    // 获取公司详情
    getConmpany () {
      this.$common.fetchList('/company/detail/'+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.conmpany = result
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/spindex.less';
</style>
